$primary-color:#f5922f;
$warning-color:#d0021b;

@import '../../styles/variables';

.footer {
  background: $dark-background;
  color: #fff;
  font-size: 15px;
  padding: 22px 0;

  .sponsor {
    width: 140px;
    background: #000;
    padding: 10px;
    border-radius: 4px;
    margin-left: 10px;
  }

  ul {
    li {
      display: inline-block;

      a {
        color: #fff;
      }
    }
  }

  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .left {

    button {
      margin-left: 10px;
      height: 30px;
      font-size: 12px;
      font-family: $roboto-bold;
      line-height: 31px;
      padding: 0 10px;
    }

    .fast-bitcoins {
      width: 200px;
      height: 36.34px;
      background: #000;
      padding: 10px 5px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      color: #fff;
      text-transform: uppercase;

      .text {
        font-size: 12px;
        margin-right: 10px;
      }

      img {
        width: 100px;
        margin-top: 4px;
      }

      &:hover {
        color: #fff;
      }
    }
  }

  .right {
    .social {
      ul {
        li {
          margin-left: 4px;

          a {
            img {
              width: 38px;
            }
          }
        }
      }
    }
  }
}

/* responsiveness */

@media (max-width: 1024px) {
  .footer {
    text-align: center;

    .label {
      margin-bottom: 10px;
    }

    .flex {
      display: block !important;
    }

    .left {
      margin-bottom: 20px;

      button {
        margin: 10px 0 0 0;
      }

      .fast-bitcoins {
        margin: 10px auto auto auto;
      }
    }

    .right {
      .social {
        ul {
          li {
            margin-left: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }
}

/* /responsiveness */
