$primary-color:#f5922f;
$warning-color:#d0021b;

@import 'core-styles/variables';

.header {
  /*overflow: hidden;*/

  &.secured {
    background: $dark-background;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
  }

  &.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
    height: 40px;
    line-height: inherit;
    padding: 0;
  }

  &.with-horizontal-menu {
    height: 88px;
  }

  .top-section {
    /* overflow: hidden;*/
    padding: 0 20px 0 20px;

    .trigger {
      position: absolute;
      top: 8px;
      justify-content: center;
      height: 40px;
      z-index: 4;

      &.drawer {
        left: 15px;
      }

      .trigger-icon {
        font-size: 25px;
      }
    }

    .logo {
      position: absolute;
      top: -4px;
      left: 50px;
      width: 150px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
        margin: 0;
      }

      .logo-text {
        font-size: 18px;
        color: $primary-color;
      }
    }

    .logo-container {
      display: flex;
      align-items: center;
      font-family: $roboto-bold;
      color: #fff;
      justify-content: center;

      .text {
        width: 70px;
        font-size: 15px;
      }

      img {
        width: 100%;
      }
    }

    .navigation {
      float: right;

      ul {
        align-items: center;
        display: flex;

        li {
          display: inline-block;
          vertical-align: top;

          .role {
            color: #fff;
            background: #194069;
            padding: 10px;
          }

          .language {
            margin-top: 2px;
          }

          a {
            color: $brand-white;
            border-bottom: 3px solid transparent;
            display: block;
            padding: 18px;
            margin-right: 15px;
            font-size: 14px;
            letter-spacing: 1px;
            text-decoration: none;
            position: relative;
            font-family: $roboto-light;

            i {
              margin-right: 5px;
            }

            &.active {
              font-family: $roboto-regular;

              i {
                font-weight: 400;
              }
            }

            &.profile-link {
              padding: 0;
              border: 2px solid #dad9d9;
              background-color: $border-light-color;
              border-radius: 10px;
              overflow: hidden;
              margin-top: 1px;
              margin-left: 10px;

              .user-image {
                .ant-avatar {
                  //vertical-align: -18px;
                  background: #e9e9e9;
                  //margin-top: 2px;

                  i {
                    margin: 0;
                  }
                }
              }
            }

            &.documentation {
              height: 35px;
              border-radius: 4px;
              margin-top: 3px;
              margin-right: 10px;
              padding: 5px 15px;
              line-height: 26px;
              color: #fff;
              display: block;
              background: $brand-green;
              font-size: 14px;
              font-family: Roboto-Regular, sans-serif;
              letter-spacing: 1px;
              text-transform: uppercase;

              &:hover {
              }
            }
          }
        }
      }
    }
  }

  .bottom-section {
    margin-top: 2px;
    border-top: 1px solid $border-light-color;

    .horizontal-menu {
      text-align: center;

      .ant-menu-horizontal {
        background: #fafafa;
      }
    }
  }
}

.mobile-only {
  display: none;
}

.ant-popover {
  z-index: 5;

  .ant-popover-inner {
    border-radius: 0px;

    .ant-popover-inner-content {
      padding: 0px;

      .popover-content {
        .user-info {
          display: flex;
          border-bottom: 1px solid $border-color;
          padding: 10px;
          width: 300px;
          word-break: break-word;

          .ant-avatar {
            width: 60px;
            height: 60px;
            line-height: 56px;
            margin-right: 10px;
            border: 2px solid #dad9d9;
            border-radius: 10px;
            background-color: $border-light-color;
            flex-shrink: 0;
          }

          .user {
            font-family: $lato-regular;
            color: #fff;

            p {
              margin: 0
            }

            .name {
              font-size: 15px;
              font-weight: bold;
              color: $primary-color
            }

            a {
              color: #fff;
              font-size: 14px;
              text-decoration: underline;
            }
          }
        }

        .other-links {

          ul {
            padding: 0;

            li {
              list-style: none;

              a {
                color: $text-color;
                font-size: 14px;
                line-height: 25px;
                padding: 10px;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

/* responsiveness */


@media (max-width: 992px) {

}


@media (max-width: 762px) {
  .header {
    &.fixed {
      padding: 0 15px;
    }

    .top-section {
      padding: 0;

      .navigation {
        ul {
          li {
            a {
              margin-right: 4px;

              &.documentation {
                .text {
                  display: none;
                }

                i {
                  margin-right: 0 !important;
                }
              }
            }
          }
        }
      }
    }

    .navigation {
      ul {
        li {
          a {
            padding: 0;
          }
        }
      }
    }

    .logo {
      left: 55px !important;
    }

    .trigger {
      display: block !important;
    }
  }
}

@media (max-width: 520px) {
  .header {
    .top-section {
      .navigation {
        ul {
          li {
            .language {
              .changeLanguage {
                &.ant-select {
                  .ant-select-selection {
                    padding: 0 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 420px) {
  .header {
    .top-section {
      .logo {
        display: none;
      }
    }
  }
}

/* /responsiveness */

