$primary-color:#f5922f;
$warning-color:#d0021b;

@import "variables.scss";

@font-face {
  font-family: Arial;
  src: url(../fonts/arial.ttf) format("truetype");
}

@font-face {
  font-family: Arial-Bold;
  src: url(../fonts/Arial_Bold.ttf) format("truetype");
}

@font-face {
  font-family: Lato-Light;
  src: url(../fonts/Lato-Light.ttf) format("truetype");
}

@font-face {
  font-family: Lato-Regular;
  src: url(../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Lato-Bold;
  src: url(../fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Thin;
  src: url(../fonts/Roboto-Thin.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Light;
  src: url(../fonts/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Regular;
  src: url(../fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: DINAlternate;
  src: url(../fonts/DINAlternate-Bold.ttf) format("truetype");
}

body {
  font-family: Roboto-Regular, sans-serif !important;
  background-color: $screen-background !important;
  top: 0 !important;
  -webkit-font-smoothing: antialiased;
}

* {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;

  li {
    list-style: none;
  }
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $primary-color;
  }
}

.main-container {
  padding-top: 0px;

  &.secured {
    padding-top: 40px;
  }

  &.no-header {
    padding-top: 0;
  }
}

.page {
  background: $screen-background;
}

.container {
  width: 93%;
  margin: auto;
}

.fill {
  background: #fafafa !important;
}

/*button*/

.ant-btn {
  height: 45px;
  padding: 0 20px;
  font-size: 14px;
  border-radius: 4px;
  font-family: $roboto-regular;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;

  i {
    margin-right: 5px;
  }

  & > .anticon + span {
    margin-left: 0;
  }

  &.primary-btn {
    background: $primary-color;
    color: #fff;
    border: none;
  }

  &.primary-btn-text {
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
      border-color: $primary-color !important;
    }
  }

  &.green-btn {
    background: $brand-green;
    color: #fff;
    border: none;
  }

  &.green-btn-text {
    border: 1px solid $border-light-color;
    color: $brand-green;

    &:hover {
      border-color: $brand-green !important;
    }
  }

  &.red-btn {
    background: $brand-red;
    color: #fff;
    border: none;

    &:hover {
      border-color: $brand-red !important;
    }
  }

  &.red-btn-text {
    border: 1px solid $border-light-color;
    color: $brand-red;

    &:hover {
      border-color: $brand-red !important;
    }
  }

  &.disabled {
    background: #9e9e9e !important;
    color: #fff !important;
  }
}

/*/button*/

/*table*/
.ant-table {
  .ant-table-content {
    .ant-table-body {
      .ant-table-tbody > tr:hover > td {
        background: transparent !important;
      }
    }
  }
}

/*/table*/

/*drop down*/
.ant-dropdown {
  .ant-dropdown-menu {
    width: 200px;
    padding: 0;
    overflow: hidden;

    .ant-dropdown-menu-submenu {
      border-top: 1px solid $border-light-color;

      &:first-child {
        border-top: none;
      }

      .ant-dropdown-menu-submenu-title {
        padding: 10px 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 12px;
        font-family: $roboto-bold;

        .ant-dropdown-menu-submenu-arrow {
          top: 15px;
        }

        &:hover {
          background-color: #fafafa;
          color: $primary-color;
        }
      }
    }
  }
}

.ant-dropdown-menu-submenu {
  .ant-dropdown-menu {
    width: 200px;
    padding: 0;
    overflow: hidden;

    .ant-dropdown-menu-item {
      padding: 10px 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 12px;
      font-family: $roboto-bold;
      border-top: 1px solid $border-light-color;

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

/*/drop down*/

/*check box*/
.ant-checkbox {
  .ant-checkbox-checked,
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner {
      background-color: $primary-color !important;
      border-color: $primary-color !important;
    }
  }
}


.ant-checkbox-checked::after {
  border: 1px solid $primary-color;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary-color;
}

/*/check box*/

.ant-calendar-range-picker-separator {
  vertical-align: inherit;
}

.ant-calendar-header .ant-calendar-prev-century-btn,
.ant-calendar-header .ant-calendar-next-century-btn,
.ant-calendar-header .ant-calendar-prev-decade-btn,
.ant-calendar-header .ant-calendar-next-decade-btn,
.ant-calendar-header .ant-calendar-prev-month-btn,
.ant-calendar-header .ant-calendar-next-month-btn,
.ant-calendar-header .ant-calendar-prev-year-btn,
.ant-calendar-header .ant-calendar-next-year-btn {
  top: 17px;
}

/*/popover*/

.ant-popover {
  .ant-popover-inner {
    border-radius: 10px;
    overflow: hidden;
  }
}

/*/popover*/

.captialize {
  text-transform: capitalize;
}

/*/select dropdown*/
.ant-select-dropdown {
  .ant-select-dropdown-menu-item {
    &:hover {
      background-color: $thumbnail-bg;
    }
  }

  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: $thumbnail-bg;
  }
}

/*/select dropdown*/

/*form*/
.ant-form {
  .actions {
    text-align: right;
    margin-top: 20px;

    button {
      margin-left: 10px;
    }
  }

}

.form {
  ::-webkit-input-placeholder {
    font-size: 14px;
  }

  ::-moz-placeholder {
    font-size: 14px;
  }

  :-ms-input-placeholder {
    font-size: 14px;
  }

  :-moz-placeholder {
    font-size: 14px;
  }

  .inline {
    display: flex;

    > .ant-row {
      padding: 0 10px;
      flex-grow: 1;
      flex-basis: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .title {
    font-size: 18px;
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: $roboto-bold;
    letter-spacing: 1px;
  }

  .description {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 200;
  }

  .ant-form-item {
    margin-bottom: 10px;

    &.ant-form-item-with-help {
      margin-bottom: 4px;
      text-align: left;

      .ant-form-explain {
        margin-top: 4px;
      }
    }
  }

  .ant-input, .input {
    height: 40px;
    background: #fff;
    border: 1px solid $border-light-color;
    font-size: 14px;
    padding-left: 12px;
    border-radius: 4px;
    overflow: hidden;

    &:focus {
      box-shadow: none;
    }

    &.textarea {
      height: 100px;
      resize: none;
      padding: 15px 12px;
    }

    &.ant-input-group-wrapper {
      padding-left: 0;
      border: none;

      .ant-input-group-addon {
        border-color: $border-light-color;

        .ant-select {
          background-color: $thumbnail-bg;
          border-right: 0;
          border-left: 0;
          border-radius: 0;

          .ant-select-selection__rendered {
            background-color: $thumbnail-bg;
            margin: 0;
          }
        }
      }
    }

    &.ant-input-disabled {
      color: #555;
      background: $thumbnail-bg;
    }

    i {
      color: $primary-color;
    }
  }

  .ant-select {
    height: 45px;
    background: #fff;
    border: 1px solid $border-light-color;
    font-size: 14px;
    padding-left: 12px;
    border-radius: 4px;
    overflow: hidden;
    vertical-align: top;

    &.multi-select {
      height: inherit;
      min-height: 45px;

      .ant-select-selection--multiple {
        border: none;
        outline: 0;
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }

        .ant-select-selection__rendered {
          margin: 5px 20px 0 0;

          .ant-select-selection__choice {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .ant-select-selection--single {
      height: 45px;
      border: none;
      background: #fff;
      box-shadow: none;

      .ant-select-selection__rendered {
        height: 45px;
        line-height: 38px;
        margin: 0 2px;

        .ant-select-selection-selected-value {
          color: #1e1f20;
          margin-top: 2px;
        }
      }
    }
  }

  .ant-input-number {
    line-height: 45px;
    width: 100%;
    box-shadow: none;

    input {
      padding-left: 0;
    }
  }

  .ant-select-auto-complete {
    border: none;
    padding: 0;

    .ant-select-selection {
      .ant-select-selection__rendered {
        height: 45px;

        .ant-input {
          height: 45px;
          border-color: $border-light-color;
        }
      }
    }
  }

  .ant-upload {
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    margin-top: 10px;

    &.ant-upload-select {
      border-radius: 4px;
    }
  }

  .ant-avatar {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 10px;
    background-color: $border-light-color;

    .anticon-user {
      font-size: 30px;
    }
  }

  .ant-calendar-picker-input {
    height: 45px;
    background: #fff;
    border: 1px solid $border-light-color;
    font-size: 14px;
    padding-left: 12px;
    border-radius: 4px;
  }

  .file-list {
    display: flex;

    .file {
      width: 220px;
      min-height: 80px;
      border: 1px solid $border-light-color;
      margin-right: 10px;
      background: #fff;
      padding: 10px 40px 10px 10px;
      border-radius: 4px;
      position: relative;

      .data {

        .uploaded-file {
          width: 200px;
          max-height: 200px;
          /* background: #fafafa;*/
          /* align-items: center;
           display: flex;
           justify-content: center;*/

          img {
            max-width: 100%;
            max-height: 100%;
            margin-bottom: 10px;
          }

        }


        .name {
          word-break: break-all;
        }

        .type {
          font-weight: bold;
        }

        .icon {
          font-size: 30px;
          margin-right: 10px;
          color: $primary-color;
        }

        .image {
          max-width: 80%;
          max-height: 100px;
          margin-right: 20px;
          border: 1px solid $border-light-color;
          border-radius: 4px;
          margin-bottom: 10px;
          background-color: $thumbnail-bg;
        }
      }

      .remove {
        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 20px;
        color: #c5c5c5;
      }

      .download {
        position: absolute;
        bottom: 0px;
        right: 10px;
        font-size: 20px;
        color: #3066a0;
      }
    }
  }

  .upload-container {
    border: 1px solid $border-light-color;
    padding: 10px;
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 4px;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .ant-form-item-control {
    line-height: inherit;
  }

  .ant-switch-checked {
    background-color: $brand-green;
  }

  .form-section {
    background: #fff;
    border-bottom: 1px solid $border-light-color;

    .section-label {
      font-weight: bold;
      padding: 10px 24px;
      border-bottom: 1px solid $border-light-color;
      background-color: $thumbnail-bg;
    }

    .content {
      padding: 24px;

      .appended-form {
        border: 1px solid $border-light-color;
        padding: 20px;
        border-radius: 4px;
        background: #fff;
      }

      .crud {
        border: 1px solid $border-light-color;
        border-radius: 4px;
        background: #fff;

        .header {
          padding: 10px 10px;
          border-bottom: 1px solid $border-light-color;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .crud-content {
          padding: 20px;

          button {
            margin-top: 38px;
            font-size: 18px;
            padding: 0 20px;
            height: 45px;

            i {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .has-error.has-feedback:after {
    top: 2px;
  }

  .has-success.has-feedback:after {
    top: 2px;
  }

  .has-error.has-feedback .ant-form-item-children-icon {
    display: none;
  }

  .has-success.has-feedback .ant-form-item-children-icon {
    display: none;
  }
}

/*/modal*/

/*modal*/

.ant-modal {
  width: 70% !important;
  top: 30px;

  .ant-modal-content {
    .ant-modal-header {
      background: $dark-background;
      border-bottom: none;

      .ant-modal-title {
        color: #fff;
        font-size: 18px;
      }
    }

    .ant-modal-close-x {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.ant-modal-confirm {
    width: 415px !important;
  }

  &.small-width {
    width: 512px !important;
  }

  &.have-form-sections {
    .ant-modal-body {
      padding: 0;

      .ant-form {
        .form {
          .form-section {
            &:last-child {
              border-bottom: none;
            }
          }
        }

        .actions {
          margin-top: 0;
          padding: 24px;
        }
      }
    }
  }
}

/*/modal*/

/* ck editor */
.cke {
  border: 1px solid $border-light-color !important;
  box-shadow: none !important;
  border-radius: 4px;
  overflow: hidden;

  .cke_top {
    padding: 0;
    border: none;
  }

  .cke_bottom {
    background: $thumbnail-bg;

    .cke_resizer {
      border-color: transparent #646464 transparent transparent;
    }
  }
}

/* /ck editor */

/* pagination */
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-pagination-item-active {
  border-color: $brand-green;

  a {
    color: $brand-green;
  }
}

/* /pagination*/

.editor-content {
  a {
    color: $primary-color;
  }

  h1, h2, h3, h4, h5, h6 {
    color: $title-color;
    font-family: $roboto-bold;
    line-height: 40px;
  }

  p {
    font-size: 16px;
    letter-spacing: 1px;
    color: $text-color;
    margin-bottom: 20px;
  }

  ul, ol {
    margin-bottom: 20px;
    padding-left: 40px;

    li {
      list-style: inherit;
      margin-bottom: 10px;
    }

    ul {
      margin-left: 18px;
    }
  }

  strong {
    color: $title-color;
  }

  table {
    border: 1px solid $border-light-color;

    tr {
      border: 1px solid $border-light-color;

      th {
        font-weight: bold;
        font-size: 15px;
        padding: 10px 20px;
        border: 1px solid $border-light-color;
        color: $title-color;
      }

      td {
        border: 1px solid $border-light-color;
        padding: 10px 20px;
      }
    }
  }

  hr {
    border: 1px solid $border-light-color;
    margin: 20px 0;
  }

  img {
    max-width: 100%;
    border-radius: 10px;
  }

  .marker {
    background-color: #fafafa;
    padding: 4px 8px;
    border-radius: 4px;
    color: $title-color;
  }

  code {
    background-color: #fafafa;
    padding: 4px 0;
    border-radius: 4px;
    color: $title-color;
  }

  pre {
    max-width: 95%;
    background: #fafafa;
    border: 1px solid $border-light-color;
    text-shadow: none;
    box-shadow: none;
    font-size: 14px;
    padding: 20px;

    code {
      text-shadow: none;
    }
  }
}

.page-section {
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    .text {
      flex-grow: 2;
      flex-basis: 0;

      .title {
        font-size: 32px;
        color: $title-color;
        font-family: $roboto-bold;
        line-height: 40px;
        text-transform: uppercase;
        margin-bottom: 10px;
      }

      .description {
        font-size: 16px;
        letter-spacing: 1px;
        color: $text-color;
      }
    }

    .action {
      flex-grow: 1;
      flex-basis: 0;
      text-align: right;
    }
  }

  .section {
    padding: 90px 0;
  }

}

/*animations*/

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

/**
  Added By Inder for general
*/
.brand-color-text {
  color: $primary-color !important;
}

/*/animations*/

/* spinner */
.spinner {
  position: absolute;
  margin: auto;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
  -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -o-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
  -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -ms-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
  -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -webkit-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
  -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  -moz-box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
  -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  animation: cssload-spin ease infinite 1.8s;
  -o-animation: cssload-spin ease infinite 1.8s;
  -ms-animation: cssload-spin ease infinite 1.8s;
  -webkit-animation: cssload-spin ease infinite 1.8s;
  -moz-animation: cssload-spin ease infinite 1.8s;
}

@keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }
  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
    12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }
  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
    12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }
  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
    -12px -12px $primary-color;
  }
}

@-o-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }
  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
    12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }
  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
    12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }
  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
    -12px -12px $primary-color;
  }
}

@-ms-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }
  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
    12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }
  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
    12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }
  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
    -12px -12px $primary-color;
  }
}

@-webkit-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }
  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
    12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }
  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
    12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }
  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
    -12px -12px $primary-color;
  }
}

@-moz-keyframes cssload-spin {
  0%,
  100% {
    box-shadow: 12px 12px $primary-color, -12px 12px rgb(223, 223, 223),
    -12px -12px $primary-color, 12px -12px rgb(223, 223, 223);
  }
  25% {
    box-shadow: -12px 12px rgba(223, 223, 223, 0.98), -12px -12px $primary-color,
    12px -12px rgb(223, 223, 223), 12px 12px $primary-color;
  }
  50% {
    box-shadow: -12px -12px $primary-color, 12px -12px rgb(223, 223, 223),
    12px 12px $primary-color, -12px 12px rgb(223, 223, 223);
  }
  75% {
    box-shadow: 12px -12px #dfdfdf, 12px 12px $primary-color, -12px 12px #dfdfdf,
    -12px -12px $primary-color;
  }
}

/* /spinner */

/* responsiveness */
@media (min-width: 1600px) {
  .container {
    width: 1500px;
  }
}

@media (max-width: 767px) {
  .inline {
    display: block !important;

    .ant-row {
      padding: 0 !important;
    }
  }

  .ant-modal {
    width: 100% !important;
    max-width: 100%;
    top: 0;
    padding: 0;
    margin: 0;

    .ant-modal-content {
      border-radius: 0;
      min-height: 100vh;
    }

    &.small-width {
      width: 100% !important;
    }
  }
}

/* /responsiveness */
